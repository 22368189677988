/**
 * @generated SignedSource<<7c223362d0feb23e71a7527db4efab0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksUseDeepLinkTagsBase$data = {
  readonly __typename: string;
  readonly entityId: string;
  readonly urlAlias: string;
  readonly " $fragmentType": "hooksUseDeepLinkTagsBase";
};
export type hooksUseDeepLinkTagsBase$key = {
  readonly " $data"?: hooksUseDeepLinkTagsBase$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseDeepLinkTagsBase">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseDeepLinkTagsBase",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "urlAlias",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityId",
      "storageKey": null
    }
  ],
  "type": "BaseWithApplicationAndUrlAlias",
  "abstractKey": "__isBaseWithApplicationAndUrlAlias"
};

(node as any).hash = "1c98e995a9d961a24c1a01f8a4f5e250";

export default node;
