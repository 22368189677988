import type { LinkTag } from "next-seo";

import { getAssetPrefix } from "scmp-app/lib/utils";

export const Title = "South China Morning Post";
export const DefaultTitleTemplate = `%s | ${Title}`;
export const PostiesTitle = "Posties";
export const PostiesDescription =
  "Elevate your kids' English language abilities with Posties, an engaging and informative weekly newspaper from the South China Morning Post. Our publication is designed to captivate the minds of young readers, providing them with a wealth of knowledge and exciting ideas to explore. With Posties, children can expand their language skills while discovering new perspectives on the world around them.";
export const PostiesAdditionalLinkTags: LinkTag[] = [
  {
    href: `${getAssetPrefix()}/posties-favicon.ico`,
    rel: "shortcut icon",
  },
  {
    href: `${getAssetPrefix()}/icons/posties-icon-192x192.png`,
    rel: "icon",
    sizes: "192x192",
  },
  {
    href: `${getAssetPrefix()}/icons/posties-icon-256x256.png`,
    rel: "icon",
    sizes: "256x256",
  },
  {
    href: `${getAssetPrefix()}/icons/posties-icon-192x192.png`,
    rel: "apple-touch-icon",
  },
  {
    href: `${getAssetPrefix()}/icons/posties-icon-192x192.png`,
    rel: "apple-touch-icon-precomposed",
  },
];
