import { atom } from "jotai";

export type TrackPageViewFunction = (data?: Record<string, unknown>) => void;
export interface Tracking {
  trackPageView: OrUndefined<TrackPageViewFunction>;
}

export const trackingAtom = atom<Tracking>({
  trackPageView: undefined,
});
