/**
 * @generated SignedSource<<84ab4a7a5e5f361cddceb97b23167117>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionPostMagazineProfileQuery$data = {
  readonly section: {
    readonly description: {
      readonly text: string | null | undefined;
    } | null | undefined;
    readonly name: string;
  };
  readonly topicQueue: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly " $fragmentSpreads": FragmentRefs<"topicLinkTopic">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "subSectionPostMagazineProfileQuery";
};
export type subSectionPostMagazineProfileQuery$key = {
  readonly " $data"?: subSectionPostMagazineProfileQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionPostMagazineProfileQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "postMagazineSubSectionTopicQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionPostMagazineProfileQuery",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormattedValue",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "text",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "topicQueue",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "postMagazineSubSectionTopicQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 5
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "topicLinkTopic"
                        }
                      ],
                      "type": "Topic",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:5)"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "cb7d3627ec17784df0bd7f9025a40df9";

export default node;
