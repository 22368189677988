/**
 * @generated SignedSource<<4477c92742bce8371d970d0ef7dc24d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksUseDynamicMetaTags$data = {
  readonly metatags: ReadonlyArray<{
    readonly key: string | null | undefined;
    readonly value: string | null | undefined;
  } | null | undefined> | null | undefined;
  readonly " $fragmentType": "hooksUseDynamicMetaTags";
};
export type hooksUseDynamicMetaTags$key = {
  readonly " $data"?: hooksUseDynamicMetaTags$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksUseDynamicMetaTags">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hooksUseDynamicMetaTags",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValuePair",
      "kind": "LinkedField",
      "name": "metatags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DynamicMetaTags",
  "abstractKey": "__isDynamicMetaTags"
};

(node as any).hash = "bac39c7e3441f446b6cda383defd2361";

export default node;
