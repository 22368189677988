/**
 * @generated SignedSource<<abe5783b51ddfa27049e6e8baaa3a6e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionStyleHeadQuery$data = {
  readonly relatedSections: {
    readonly items: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly entityId?: string;
          readonly name?: string;
          readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly section: {
    readonly fullSectionPath: ReadonlyArray<{
      readonly entityUuid: string;
      readonly images: ReadonlyArray<{
        readonly url: string;
      } | null | undefined> | null | undefined;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
    } | null | undefined> | null | undefined;
    readonly images: ReadonlyArray<{
      readonly url: string;
    } | null | undefined> | null | undefined;
    readonly name: string;
    readonly parentSection: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"entityLink">;
  };
  readonly " $fragmentType": "subSectionStyleHeadQuery";
};
export type subSectionStyleHeadQuery$key = {
  readonly " $data"?: subSectionStyleHeadQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionStyleHeadQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "entityLink"
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "filter",
      "value": {
        "type": {
          "values": [
            "logo"
          ]
        }
      }
    }
  ],
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": "images(filter:{\"type\":{\"values\":[\"logo\"]}})"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "relatedSectionsQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionStyleHeadQuery",
  "selections": [
    {
      "alias": "relatedSections",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "name",
              "variableName": "relatedSectionsQueueName"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Queue",
      "kind": "LinkedField",
      "name": "queue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10
            }
          ],
          "concreteType": "QueueItemConnection",
          "kind": "LinkedField",
          "name": "items",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QueueItemsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v0/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "entityId",
                          "storageKey": null
                        }
                      ],
                      "type": "Section",
                      "abstractKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "items(first:10)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "entityId",
              "variableName": "entityId"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "Section",
      "kind": "LinkedField",
      "name": "section",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentSection",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Section",
          "kind": "LinkedField",
          "name": "fullSectionPath",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "entityUuid",
              "storageKey": null
            },
            (v2/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "f9ded48a6f18f75508f69a8ef35299ca";

export default node;
