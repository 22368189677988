import { useRouter } from "next/router";
import qs from "qs";
import { useCallback } from "react";

export const useRouterReplaceWithSanitizedKeys = () => {
  const { replace } = useRouter();
  type ReplaceType = Parameters<typeof replace>;
  return useCallback(
    (
      sanitizedKeys: string[],
      url: ReplaceType[0],
      options: ReplaceType[2],
      extraQueryString = {},
    ) => {
      const { restOfTheQueryString } = sanitizedParametersByKeys(
        sanitizedKeys,
        window.location.search,
      );
      const asPath = new URL(location.href);
      asPath.search = qs.stringify({ ...restOfTheQueryString, ...extraQueryString });
      return replace(url, asPath.toString(), options);
    },
    [replace],
  );
};

export const sanitizedParametersByKeys = (keys: string[], parameters: string) => {
  const parsedQueryString = qs.parse(parameters, {
    ignoreQueryPrefix: true,
  });

  const sanitizedQueryString: qs.ParsedQs = {};
  const restOfTheQueryString: qs.ParsedQs = {};
  for (const queryKey of Object.keys(parsedQueryString)) {
    if (keys.includes(queryKey)) {
      sanitizedQueryString[queryKey] = parsedQueryString[queryKey];
    } else {
      restOfTheQueryString[queryKey] = parsedQueryString[queryKey];
    }
  }

  return { restOfTheQueryString, sanitizedQueryString };
};
