/**
 * @generated SignedSource<<d93b5b3cf7187aad151ea5206dc40ff8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type subSectionStyleContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"subSectionStyleArticleListQuery" | "subSectionStyleHeadQuery">;
  readonly " $fragmentType": "subSectionStyleContentQuery";
};
export type subSectionStyleContentQuery$key = {
  readonly " $data"?: subSectionStyleContentQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"subSectionStyleContentQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "articlesQueueName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "entityId"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "relatedSectionsQueueName"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "subSectionStyleContentQuery",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "entityId",
          "variableName": "entityId"
        },
        {
          "kind": "Variable",
          "name": "relatedSectionsQueueName",
          "variableName": "relatedSectionsQueueName"
        }
      ],
      "kind": "FragmentSpread",
      "name": "subSectionStyleHeadQuery"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "articlesQueueName",
          "variableName": "articlesQueueName"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "subSectionStyleArticleListQuery"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "c35e25f4fd81469d062cabbd52fdb8e6";

export default node;
