/**
 * @generated SignedSource<<4a3f447f2f90f570a65ad66afdcfdb68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSubSectionWidgetAndAdsQuery$data = {
  readonly parentSection: {
    readonly entityId: string;
  };
  readonly subSection: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"commentQueueQuery" | "discoveryWidgetPostMagazineQueueQuery" | "focusArticleHomeFocusArticleQuery" | "hundredTopTablesDiscoveryWidgetQuery" | "multimediaQuery" | "openQuestionsSeriesDiscoveryModuleQuery">;
  readonly " $fragmentType": "hooksSubSectionWidgetAndAdsQuery";
};
export type hooksSubSectionWidgetAndAdsQuery$key = {
  readonly " $data"?: hooksSubSectionWidgetAndAdsQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSubSectionWidgetAndAdsQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSubSectionWidgetAndAdsQuery"
};

(node as any).hash = "389a4c1dcb2e176269bb10e080b8a449";

export default node;
