export enum ControlledTagKey {
  AppLink = "appLink",
  Canonical = "canonical",
  DeepLinkAndroid = "al:android:url",
  DeepLinkIOS = "al:ios:url",
  Description = "description",
  Facebook = "facebook",
  Keywords = "keywords",
  NewsKeywords = "news_keywords",
  Title = "title",
  Twitter = "twitter",
}

export const isControlledTagKey = (key: string): key is ControlledTagKey =>
  Object.values(ControlledTagKey).includes(key as ControlledTagKey);
