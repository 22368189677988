import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { TargetingHomePageTakeOverType } from "scmp-app/components/advertisement/ad-slots/types";
import { TakeoverAdSlot } from "scmp-app/components/advertisement/takeover-ad-slot";
import { useTakeoverBottomAdSlot } from "scmp-app/components/advertisement/takeover-ad-slot/hooks";
import { AfterPageLayout } from "scmp-app/components/after-page-layout";
import { AppFooter } from "scmp-app/components/app-footer";
import { ClientSideSuspense } from "scmp-app/components/common/client-side-suspense";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { MagazinesStyleVariant } from "scmp-app/components/section/section-style/const";
import { SectionStyleNewsletter } from "scmp-app/components/section/section-style/section-style-newsletter";
import { StoryCardCarouselWidget } from "scmp-app/components/section/section-style/section-style-story-card-carousel-widget";
import type { subSectionStyleContentQuery$key } from "scmp-app/queries/__generated__/subSectionStyleContentQuery.graphql";

import { SubSectionStyleArticleList } from "./sub-section-style-article-list";
import { SubSectionStyleHead } from "./sub-section-style-head";

export type Props = {
  reference: subSectionStyleContentQuery$key;
};
export const SubSectionStyle: FunctionComponent<Props> = ({ reference }) => {
  const content = useFragment(
    graphql`
      fragment subSectionStyleContentQuery on Query
      @argumentDefinitions(
        after: { type: "String" }
        articlesQueueName: { type: "String!" }
        entityId: { type: "String!" }
        first: { type: "Int", defaultValue: 30 }
        relatedSectionsQueueName: { type: "String!" }
      ) {
        ...subSectionStyleHeadQuery
          @arguments(entityId: $entityId, relatedSectionsQueueName: $relatedSectionsQueueName)
        ...subSectionStyleArticleListQuery
          @arguments(after: $after, articlesQueueName: $articlesQueueName, first: $first)
      }
    `,
    reference,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();
  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [
        [970, 250],
        [1, 1],
        [480, 360],
        [480, 270],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        hpto: TargetingHomePageTakeOverType.Top,
      },
      zone: baseAdvertisement.zone,
    },
    mobile: {
      adUnit: "m_banner3",
      sizes: [[300, 50], [300, 100], [320, 50], [320, 100], "fluid"],
      targeting: {
        ...baseAdvertisement.targeting,
        hpto: TargetingHomePageTakeOverType.Top,
      },
      zone: baseAdvertisement.zone,
    },
  });

  const { isEnableTakeoverBottomAdSlot } = useTakeoverBottomAdSlot({
    variant: MagazinesStyleVariant,
  });

  return (
    <>
      <SubSectionStyleHead reference={content} />
      <SubSectionStyleArticleList reference={content}>
        <SectionStyleNewsletter />
        <ClientSideSuspense>
          <AppFooter variant="magazines-style" />
        </ClientSideSuspense>
      </SubSectionStyleArticleList>
      <ClientSideSuspense>
        <AfterPageLayout>
          {isEnableTakeoverBottomAdSlot ? (
            <TakeoverAdSlot adSlotProps={baseAdvertisement} variant={MagazinesStyleVariant} />
          ) : (
            <StoryCardCarouselWidget />
          )}
        </AfterPageLayout>
      </ClientSideSuspense>
    </>
  );
};

SubSectionStyle.displayName = "SubSectionStyle";
