import { graphql, readInlineData } from "react-relay";
import CreateSeedRandom from "seed-random";

import type { hooksPickSubSectionArticle$key } from "scmp-app/queries/__generated__/hooksPickSubSectionArticle.graphql";

export const usePickSubSectionArticles = (
  seed: string,
  reference?: hooksPickSubSectionArticle$key | null,
) => {
  const random = CreateSeedRandom(seed);
  const data = readInlineData(
    graphql`
      fragment hooksPickSubSectionArticle on Queue
      @inline
      @argumentDefinitions(first: { type: "Int" }) {
        items(first: $first) {
          edges {
            # eslint-disable-next-line relay/unused-fields
            node {
              ... on Content {
                ...postMagazinePrimaryContent
              }
            }
          }
        }
      }
    `,
    reference ?? null,
  );

  const slotArticles = data?.items?.edges ?? [];
  const slot1Picked = Math.floor(random() * slotArticles.length);

  return slotArticles.length > slot1Picked ? slotArticles[slot1Picked] : slotArticles.at(-1);
};
