import type { Options } from "deepmerge";
import merge from "deepmerge";
import type { LinkTag, MetaTag, NextSeoProps } from "next-seo";
import { NextSeo } from "next-seo";
import type { FunctionComponent } from "react";

import { config } from "scmp-app/data";
import { Edition, editionMetaDescription } from "scmp-app/lib/edition";
import { useCanonicalUrl } from "scmp-app/lib/hooks";
import { getAssetPrefix } from "scmp-app/lib/utils";

import { DefaultTitleTemplate, Title } from "./consts";
import DefaultImage from "./default-image.png";
import { useKeywordMetaTags } from "./hooks";

export type Props = {
  isAppLinksDisabled?: boolean;
  options?: Options;
} & NextSeoProps;

export const PageSeo: FunctionComponent<Props> = ({
  isAppLinksDisabled,
  options = {},
  ...extraSeoProps
}) => {
  // default description
  const description = editionMetaDescription[Edition.International];
  const { canonicalUrl } = useCanonicalUrl();
  const keywordMetaTags = useKeywordMetaTags();

  /**
   * a bug reported by QA: https://scmp-product-tech.atlassian.net/browse/SCMPWEB-3496
   * said that the page title will change to the default title we pass to <DefaultSeo>
   * and back to desired title during the hydration process.
   *
   * the reason is that the title we passed to <NextSeo> will be unmounted by the 'WithRelay' suspense.
   * The <DefaultSeo> use the default title. Then the suspense result, the page mount again,
   * the desired title appear again.
   *
   * as the conclusion, we don't use <DefaultSeo> but using this wrapper component of <NextSeo> to provide default values.
   * during suspense, no <DefaultSeo> is executed, the page seo meta tags & title will remain as in the SSR source code.
   *
   * P.S. the '{...extraSeoProps}' should be at the end so that the page using this component can override the props.
   */

  const additionalMetaTags = isAppLinksDisabled
    ? [...keywordMetaTags]
    : [...appLinksMetaTags, ...keywordMetaTags];

  const defaultProps: NextSeoProps = {
    additionalLinkTags: [appManifestLinkTag, ...appIconLinkTags],
    additionalMetaTags,
    canonical: canonicalUrl,
    defaultTitle: Title,
    description,
    facebook: { appId: config.social.facebook.appId },
    openGraph: {
      images: extraSeoProps.openGraph?.images?.length ? [] : [{ url: DefaultImage.src }],
      siteName: Title,
      type: "website",
    },
    robotsProps: { maxImagePreview: "large" },
    titleTemplate: DefaultTitleTemplate,
    twitter: {
      cardType: "summary_large_image",
      site: `@${config.social.twitter.scmp.accountName}`,
    },
  };

  const filteredProps = Object.fromEntries(
    Object.entries(merge(defaultProps, extraSeoProps, options)).filter(
      ([_, value]) => value !== undefined,
    ),
  );

  return <NextSeo {...filteredProps} />;
};
PageSeo.displayName = "PageSeo";

const appLinksMetaTags: MetaTag[] = [
  {
    content: config.app.ios.storeId,
    property: "al:ios:app_store_id",
  },
  {
    content: config.app.ios.name,
    property: "al:ios:app_name",
  },
  {
    content: config.app.android.package,
    property: "al:android:package",
  },
  {
    content: config.app.android.name,
    property: "al:android:app_name",
  },
  {
    content: `app-id=${config.app.ios.storeId}`,
    name: "apple-itunes-app",
  },
];

const appManifestLinkTag: LinkTag = {
  href: "/static/manifest.json",
  rel: "manifest",
};

const appIconLinkTags: LinkTag[] = [
  {
    href: `${getAssetPrefix()}/favicon.ico`,
    rel: "shortcut icon",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-192x192.png`,
    rel: "icon",
    sizes: "192x192",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-256x256.png`,
    rel: "icon",
    sizes: "256x256",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-192x192.png`,
    rel: "apple-touch-icon",
  },
  {
    href: `${getAssetPrefix()}/icons/scmp-icon-192x192.png`,
    rel: "apple-touch-icon-precomposed",
  },
];

export const ViewportTag = {
  content: "width=device-width, maximum-scale=1, user-scalable=no, initial-scale=1",
  name: "viewport",
};

export const defaultAdditionalMetaTags = [
  {
    content: "max-image-preview:large",
    keyOverride: "robot-1",
    name: "robots",
  },
  {
    content: "25fd909c3fd10ded451415fc076ba8",
    name: "pocket-site-verification",
  },
  {
    content: "#ffffff",
    name: "theme-color",
  },
  {
    content: "yes",
    name: "mobile-web-app-capable",
  },
  ViewportTag,
];
