import { theme } from "@product/scmp-sdk";

import type { Props as AdSlotProps } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { section as sectionData } from "scmp-app/data/section";

import type { SubSectionWidget } from "./types";

export const getIncludeSectionFeatureMap = (entityId: string) => {
  const widgets = getSubSectionWidgets(entityId);
  return {
    includeComment: widgets.includes("comment"),
    includeFocusArticle: widgets.includes("focus-article"),
    includeMultimedia: widgets.includes("multimedia"),
    includePostMagazine: widgets.includes("post-magazine"),
  };
};

export const getSubSectionWidgets = (entityId: string): SubSectionWidget[] => {
  switch (entityId) {
    case sectionData.announcements.entityId:
    case sectionData.recap.entityId:
      return ["trending-topics"];
    case sectionData.asia.eastAsia.entityId:
    case sectionData.asia.southAsia.entityId:
    case sectionData.comment.asiaOpinion.entityId:
    case sectionData.comment.chinaOpinion.entityId:
    case sectionData.comment.worldOpinion.entityId:
    case sectionData.news.china.diplomacy.entityId:
    case sectionData.news.china.politics.entityId:
    case sectionData.news.hongKong.politics.entityId:
    case sectionData.thisWeekInAsia.economics.entityId:
    case sectionData.thisWeekInAsia.opinion.entityId:
    case sectionData.world.africa.entityId:
    case sectionData.world.americas.entityId:
    case sectionData.world.europe.entityId:
    case sectionData.world.middleEast.entityId:
    case sectionData.world.russiaCentralAsia.entityId:
    case sectionData.world.unitedStatesCanada.entityId:
      return ["open-questions-series-discovery", "comment", "trending-topics", "multimedia"];
    case sectionData.chinaFutureTech.aerospace.entityId:
    case sectionData.chinaFutureTech.ai.entityId:
    case sectionData.chinaFutureTech.biomedicine.entityId:
    case sectionData.chinaFutureTech.evs.entityId:
    case sectionData.chinaFutureTech.robotics.entityId:
    case sectionData.chinaFutureTech.semiconductors.entityId:
    case sectionData.us.usElections.analysis.entityId:
    case sectionData.us.usElections.explainers.entityId:
    case sectionData.us.usElections.latest.entityId:
      return ["focus-article", "comment", "trending-topics", "multimedia"];
    case sectionData.lifestyle.artsAndCulture.entityId:
    case sectionData.lifestyle.familyAndRelationships.entityId:
    case sectionData.lifestyle.healthAndWellness.entityId:
    case sectionData.lifestyle.travelAndLeisure.entityId:
    case sectionData.news.peopleAndCulture.chinaPersonalities.entityId:
    case sectionData.news.peopleAndCulture.environment.entityId:
    case sectionData.news.peopleAndCulture.genderAndDiversity.entityId:
    case sectionData.news.peopleAndCulture.socialWelfare.entityId:
    case sectionData.news.peopleAndCulture.trendingInChina.entityId:
      return ["post-magazine", "comment", "multimedia", "trending-topics"];
    case sectionData.lifestyle.foodAndDrink.entityId:
    case sectionData.lifestyle.hundredTopTables.entityId:
    case sectionData.news.hongKong.healthAndEnvironment.entityId:
      return ["100-top-tables", "comment", "trending-topics", "multimedia"];
    default:
      return ["comment", "trending-topics", "multimedia"];
  }
};

export const getSubSectionAdsConfigs = (sectionId: string, adZone = "default") => {
  const defaultAdSlotProps: Partial<AdSlotProps> = {
    targeting: {
      paid: TargetingPaidType.Free,
      scsid: [sectionId],
    },
    withBackground: true,
    withLabel: true,
    withSticky: true,
    zone: adZone,
  };

  const configs: Array<[AdSlotProps, AdSlotProps]> = [
    // AD2 = FirstAds in grid layout
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec1",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], [300, 600], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_banner1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD3
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec1",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], "fluid"],
      },
    ],
    // AD4
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec3a",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], [300, 600], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec3",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
    // AD5
    [
      {
        ...defaultAdSlotProps,
        adUnit: "d_lrec2",
        breakpoint: theme.breakpoints.up("tablet"),
        sizes: [[300, 250], "fluid"],
      },
      {
        ...defaultAdSlotProps,
        adUnit: "m_lrec2",
        breakpoint: theme.breakpoints.only("mobile"),
        sizes: [[300, 250], [320, 480], "fluid"],
      },
    ],
  ];

  return configs;
};
