import { useSyncedRef } from "@react-hookz/web";
import { useEffect } from "react";

import { useIntersection } from "./intersection";

type InfiniteScrollTriggerArguments = {
  disabled?: boolean;
  hasNextPage: boolean;
  isLoading: boolean;
  onLoadMore: VoidFunction;
  options?: IntersectionObserverInit;
};

export const useInfiniteScrollTriggerReference = ({
  disabled,
  hasNextPage,
  isLoading,
  onLoadMore,
  options = {
    threshold: [0.25, 1],
  },
}: InfiniteScrollTriggerArguments) => {
  const { captureIntersectionElement, intersection, setShouldDisable } = useIntersection(options);
  const latestCallback = useSyncedRef(onLoadMore);

  /*
    Disable intersection observation during loading to avoid unnecessary calls
    which caused by isLoading become false again after the fetch is done
    then trigger the useEffect again with intersection still true as the trigger element still in viewport.
  */
  useEffect(() => {
    setShouldDisable(isLoading);
  }, [isLoading, setShouldDisable]);

  // Use isLoading here to prevent the intersection observer keeps on the screen and stop to fetch next page
  useEffect(() => {
    const shouldLoadMore = !disabled && !isLoading && hasNextPage && intersection?.isIntersecting;
    if (!shouldLoadMore) return;
    latestCallback.current();
  }, [disabled, hasNextPage, intersection?.isIntersecting, isLoading, latestCallback]);

  return { infiniteScrollTriggerReference: captureIntersectionElement };
};
