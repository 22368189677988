import styled from "@emotion/styled";
import { fontBodoni, fontIndivisibleVariable } from "@product/scmp-sdk";

import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  position: relative;

  inline-size: 100%;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: block;

  color: #111111;
  font-weight: 400;
  font-size: 24px;
  font-family: ${fontBodoni};
  line-height: 110%;
  letter-spacing: -0.72px;

  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 32px;
    letter-spacing: -0.96px;
  }
`;

export const Headline = styled.div`
  font-size: 24px;
  ${props => props.theme.breakpoints.up("tablet")} {
    font-size: 28px;
  }

  ${Flag} {
    color: #111111;
  }
`;

export const StyledCoverEntityLink = styled(EntityLink)`
  aspect-ratio: 3/2;

  display: block;

  margin-block-end: 20px;

  ${props => props.theme.breakpoints.up("tablet")} {
    aspect-ratio: 990/396;

    margin-block-end: 30px;
  }
`;

export const CoverImage = styled.div`
  position: relative;
  aspect-ratio: 3/2;

  inline-size: 100%;
  block-size: 100%;

  ${props => props.theme.breakpoints.up("tablet")} {
    aspect-ratio: 990/396;
  }
`;

export const Topic = styled.div`
  > a {
    margin-block-start: 12px;

    color: #111111;
    font-weight: 500;
    font-size: 13px;
    font-family: ${fontIndivisibleVariable};
    line-height: normal;
    letter-spacing: -0.013px;
    text-transform: uppercase;

    font-variation-settings: "wght" 500;

    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: 16px;

      font-size: 16px;
      letter-spacing: -0.016px;
    }
  }
`;
