import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";
import type { SwiperProps } from "swiper/react";

import type { AppBarConfiguration } from "scmp-app/components/app-bar/types";
import { StyledSwiper, StyledSwiperSlide } from "scmp-app/components/entity-oneline-menu/styles";
import type { subSectionPostMagazineProfileQuery$key } from "scmp-app/queries/__generated__/subSectionPostMagazineProfileQuery.graphql";

import { Container, Description, Explore, Name, StyledTopicLink, Tags } from "./styles";

export type Props = {
  appBarConfiguration?: AppBarConfiguration;
  className?: string;
  reference: subSectionPostMagazineProfileQuery$key;
};

export const SubSectionPostMagazineProfile: FunctionComponent<Props> = ({
  className,
  reference: reference_,
}) => {
  const { section, topicQueue } = useFragment(
    graphql`
      fragment subSectionPostMagazineProfileQuery on Query
      @argumentDefinitions(
        entityId: { type: "String!" }
        postMagazineSubSectionTopicQueueName: { type: "String!" }
      ) {
        section(filter: { entityId: $entityId }) {
          name
          description {
            text
          }
        }
        topicQueue: queue(filter: { name: $postMagazineSubSectionTopicQueueName }) {
          items(first: 5) {
            edges {
              node {
                ... on Topic {
                  entityId
                  ...topicLinkTopic
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const swiperSettings = {
    breakpoints: {
      0: {
        direction: "horizontal",
      },
      768: {
        direction: "vertical",
      },
    },
    spaceBetween: 16,
  } as SwiperProps;

  return (
    <Container className={className}>
      <Name>{section.name}</Name>
      <Description>{section.description?.text}</Description>
      <Explore>explore {section.name}</Explore>
      <Tags>
        <StyledSwiper cssMode={false} pagination={false} slidesPerView="auto" {...swiperSettings}>
          {topicQueue?.items?.edges?.map(({ node }) => (
            <StyledSwiperSlide key={node?.entityId}>
              <StyledTopicLink reference={node} />
            </StyledSwiperSlide>
          ))}
        </StyledSwiper>
      </Tags>
    </Container>
  );
};

SubSectionPostMagazineProfile.displayName = "SubSectionPostMagazineProfile";
