import type { FragmentRefs } from "relay-runtime";

import type { subSectionPostMagazineIndexArticleList$data } from "scmp-app/queries/__generated__/subSectionPostMagazineIndexArticleList.graphql";

const chunkArray = (
  contentEdges: {
    readonly " $fragmentSpreads": FragmentRefs<
      "postMagazinePrimaryContent" | "postMagazineSecondaryContent"
    >;
  }[],
  hasNextPage: boolean,
) => {
  const chunks = [];
  const chunkSize = 7;

  for (let index = 0; index < contentEdges.length; index += chunkSize) {
    const chunk = contentEdges.slice(index, index + chunkSize);
    if (chunk.length === chunkSize) {
      chunks.push(chunk);
    } else if (!hasNextPage) {
      chunks.push(chunk);
    }
  }

  return chunks;
};

export const parseList = (
  hasNextPage: boolean,
  contentList?: null | subSectionPostMagazineIndexArticleList$data,
) => {
  const contentEdges = contentList?.items?.edges.map(({ node }) => node) ?? [];

  const maximinContent = 48;
  const heroContentsGroupSize = 6;
  const heroContentsGroup = contentEdges?.slice(0, heroContentsGroupSize);

  const restContentsComponents = chunkArray(
    contentEdges?.slice(heroContentsGroupSize, maximinContent),
    hasNextPage,
  );

  return {
    heroContentsGroup,
    restContentsComponents,
  };
};
